.project-container {
  display: grid;
  grid-template-columns: minmax(300px, 1fr) minmax(300px, 1fr);
  width: 75vw;
  /* max-width: 1800px; */
  min-height: 300px;
  max-height: fit-content;
  padding: 20px;
  margin: 0 auto;
  gap: 3rem;
  background-color: var(--light);
  border-radius: 15px;
  background-clip: padding-box;
  position: relative;
  /* z-index: 1; */
}

.project-container::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(90deg, var(--accent), #2888e8);
  z-index: -1;
  border-radius: 15px;
}

.project-info {
  grid-column-start: 1;
  grid-column-end: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 5rem;
}

.project-images-container {
  grid-column-start: 2;
  grid-column-end: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.carousel-container {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  /* gap: 10px; */
  position: relative;
}

.carousel-section {
  flex: 0 0 100%;
  display: flex;
  /* gap: 10px; */
}

.carousel-section img {
  max-width: 100%;
  max-height: 800px;
  /* height: auto; */
  object-fit: contain;
  object-position: center;
  margin: 0 auto;
}

.controls {
  /* position: absolute;
  bottom: 20px; */
  /* left: 50%; */
  /* transform: translateX(-50%); */

}

.controls span {
  width: 40px;
  height: 40px;

  line-height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.28);;
  border: none;
  border-radius: 5px;
  color: var(--dark);
  cursor: pointer;
  font-size: 40px;
  text-align: center;

}

.controls span:hover {
  background: rgba(0, 0, 0, 0.8);
}

.controls #left-btn {
left: 0;
}

.controls #right-btn {
  right: 0;
}

.project-info > h2 {
  font-size: 1.8rem;
  font-weight: 400;
  font-family: var(--headingFont);
  color: var(--dark);
  text-align: left;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
}

.project-info > .project-desc {
  font-size: 1.4rem;
  font-family: var(--bodyFont);
  font-weight: 400;
  color: var(--dark);
  text-align: left;
  padding: 0.5rem 1.5rem 1.5rem 1.5rem;
}

.project-links {
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
}

.project-links a {
  background: var(--accent);
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--dark);
  padding: 2px;
  text-decoration: none;
  font-family: var(--bodyMediumFont);
  border-radius: 15px;
}

.project-links a span {
  background: var(--light);
  display: block;
  padding: 1rem;
  border-radius: 15px;
}

.project-info .project-links a:hover {
  box-shadow: 4px 4px 10px rgba(89, 153, 217, 0.619);
  transform: scale(0.98);
}

.project-info > .project-technologies {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 1rem 1rem 1.5rem;
}

.project-info > .project-technologies li {
display: flex;
padding: 1rem 1.8rem 0 0;
}

.project-info > .project-technologies li img {
  /* width: 24px;
  height: 24px; */
}

.project-info > .project-technologies li span {
  font-size: 1.2rem;
  font-weight: 400;
  font-family: var(--bodyMediumFont);
  color: var(--dark);
  align-self: center;
  padding: 0 0 0 0.5rem;
}


@media screen and (max-width: 2000px) {
  .project-container {
    width: 90vw;
    gap: 2rem;
  }

  .project-info {
    padding-right: 2rem;
  }
}

@media screen and (max-width: 1400px) {
  .project-container {
    width: 90vw;
    gap: 1rem;
    grid-template-columns: minmax(300px, 1fr)
  }

  .project-info {
    padding-right: 0;
  }

  .project-images-container {
    grid-column-start: 1;
    grid-column-end: 2;
  }
}

@media screen and (max-width: 900px) {
  .project-container {
    width: 90vw;
    gap: 0.5;
    grid-template-columns: minmax(300px, 1fr);
    padding: 5px;
  }

  .project-info {
    padding-right: 0;
  }

  .project-images-container {
    grid-column-start: 1;
    grid-column-end: 2;
    padding-bottom: 1rem;
  }

  .project-info > h2 {
    font-size: 1.3rem;
    padding: 1.5rem 1rem 1.5rem 1rem;
  }

  .project-info > .project-desc {
    font-size: 1.1rem;
    padding: 0.5rem 1rem 1.5rem 1rem;;
  }

  .project-links a {
    font-size: 1.3rem;
    /* border-radius: 18px; */

  }

  .project-links a span {
    padding: 0.8rem;
    /* border-radius: 17px; */
  }

  .project-info .project-links a:hover {
    box-shadow: 2px 2px 6px rgba(89, 153, 217, 0.619);
  }

  .project-info > .project-technologies {
    padding: 1rem 0.5rem 1rem 1rem;
  }

  .project-info > .project-technologies li {
    padding: 0.5rem 0.8rem 0 0;
  }

  .project-info > .project-technologies li span {
    font-size: 1.0rem;
    padding-left: 0.4rem;
  }

  .project-info > .project-technologies li img {
    width: 17px;
    height: 17px;
  }


}
